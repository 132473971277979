<template>
  <SideBar @toggle="$emit('toggle')" class="is-large" ref="sideBar">
    <div class="column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop doodle-display-box">
      <DoodleMessage :message="message"
                     :message-class="messageClass"/>
      <div class="has-text-centered margin-top-20px">
        <button class="button has-text-weight-semibold" @click="$emit('toggle')">Close</button>
      </div>
    </div>
  </SideBar>
</template>

<script>
import SideBar from '../Utils/SideBar'
import DoodleMessage from './DoodleMessage'

export default {
  name: 'messageSideBar',
  components: { DoodleMessage, SideBar },
  props: ['message', 'messageClass'],
  data: function () {
    return {
    }
  }
}
</script>
